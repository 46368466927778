@import './../../../theme/theme';

.filtersContainer {
  position: fixed;
  top: 20px;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 13;
  @include xsPhoneView {
    top: 150px;
  }
}

.filterButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  max-width: min-content;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  line-height: 16px;
  cursor: pointer;
  z-index: 11;
  gap: 5px;
  > .filterButtonText {
    font-size: $fontSmall;
    font-weight: 700;
  }
  &.selected {
    background: $backgroundBase;
  }
}

.filterButtonContainer {
  .dropdownIcon {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $primaryFontColor;
    &.selected {
      transform: rotateX(180deg);
    }
  }
  > .dropdownContainer {
    display: flex;
    top: 10px;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background: $backgroundColor;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    animation: fade_in_show 0.5s;
  }
}

.filterToggleRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  height: 48px;
  color: $primaryFontColor;
  svg {
    width: 15px;
  }
}

.filterInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10px;
  cursor: pointer;
}

.filterTitle {
  font-weight: 700;
}

@include smTabletView {
  .filtersContainer {
    top: 100px;
  }
}

@include mdTabletView {
  .filtersContainer {
    top: 110px;
    left: 400px;
  }
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

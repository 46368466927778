$primaryColor: var(--primary-color);
$primaryDarkColor: var(--primary-dark-color);
$primaryFontColor: var(--primary-font-color);
$dividerColor: var(--divider-color);
$secondaryColor: var(--secondary-color);
$backgroundColorDark: var(--background-color-dark);
$backgroundColor: var(--background-color);
$backgroundBase: var(--background-base);
$highlightBackground: var(--highlight-background);
$light: var(--light);
$dark: var(--dark);
$dangerColor: var(--danger-color);
$undecadeFill: var(--undecade-fill);
$mapboxIcon: var(--mapbox-icon);
$disabledFontColor: var(--disabled-font-color);
$primaryLightColor: var(--primary-light-color);
$topProjectBackgroundColor: var(--top-project-background-color);
$nonDonatableProjectBackgroundColor: var(
  --non-donatable-project-background-color
);
$primaryColorNew: var(--primary-color-new);
$deforestrationRangeBackgroundColorNew: var(
  --deforestration-range-background-new
);
$horizontalLineColor: var(--horizontal-line-color);
$abandonmentBackgroundColorNew: var(--abandonment-background-color-new);
$moreInfoBackgroundColorNew: var(--more-info-background-color-new);
$certificationBackgroundColorNew: var(--certification-background-color-new);
$contactDetailBackgroundColorNew: var(--contactDetail-background-color-new);
$certificationLinkColorNew: var(--certification-link-color-new);
$secondaryDividerColorNew: var(--secondary-divider-color-new);
$grayFontColorNew: var(--gray-font-color-new);
$lightGrayBackgroundColorNew: var(--light-gray-background-color-new);
$boldFontColorNew: var(--bold-font-color-new);
$selectedMenuItemColorNew: var(--selected-menu-item-color-new);
$dividerColorNew: var(--divider-color-new);
$reviewFontColorNew: var(--review-font-color-new);
$aboutProjectBackgroundColorNew: var(--about-project-background-color-new);
$dangerColorNew: var(--danger-color-new);
$borderColor: var(--border-color);
$mediumBlueColor: var(--medium-blue-color);
$skyBlueColor: var(--sky-blue-color);
$electricPurpleColor: var(--electric-purple-color);
$lavenderPurpleColor: var(--lavender-purple-color);
$greenHazeColor: var(--green-haze-color);
$mintGreenColor: var(--mint-green-color);
$amethystPurpleColor: var(--amethyst-purple-color);
$ceruleanBlueColor: var(--cerulean-blue-color);
